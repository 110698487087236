import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { register } from '../Services/Register';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import LoadingOverlay from "./LoadingOverlay";
import '../Css/Register.css'
import Menu from './BottomMenu';

const Register = () => {

    const [processing, setProcessing] = useState(false);

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        mobileNo: '',
        emailId: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({ emailId: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validate email immediately when user types
        if (name === 'emailId') {
            const emailError = validateEmail(value) ? '' : 'Invalid email format';
            setErrors((prevErrors) => ({ ...prevErrors, emailId: emailError }));
        }

         // Clear error message when the user starts typing
        if (name === 'mobileNo') {
            const mobileError = validateMobileNumber(value) ? '' : 'Invalid mobile number';
            setErrors((prevErrors) => ({ ...prevErrors, mobileNo: mobileError }));
        }

        setFormData({ ...formData, [name]: value });
    };


    

    //function to validate email id
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobileNumber = (mobileNo) => {
        // Regex for validating 10 digit mobile number
        const mobileNumberRegex = /^\d{10}$/;
        return mobileNumberRegex.test(mobileNo);
    }

    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true)
        setError(null);

        if (formData.password === formData.confirmPassword) {
            if (validateEmail(formData.emailId) && validateMobileNumber(formData.mobileNo)) {
                register(formData).then((response) => {
                    if (response) {
                        toast.success("You are registered sucessfully, please login", { position: 'bottom-center' })
                        setProcessing(false)
                        navigate('/login')
                    }
                }
                ).catch((error) => {
                    toast.error(error.response.data.message, { position: 'bottom-center' })
                    setProcessing(false);
                }
                )
            } else {
                setProcessing(false)
                toast.error("Plese enter a valid email id!", { position: 'bottom-center' })
            }

        } else {
            setProcessing(false)
            toast.error("password and confirmed password are not same, Please enter again!", { position: 'bottom-center' })

        }

    };

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [pathname]);

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className='register-wrap justify-content-center align-items-center d-flex'>
                <div className='register-c'>
                    <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
                        <div className="h4 mb-3 text-center">Register</div>

                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Mobile No"
                                name="mobileNo"
                                value={formData.mobileNo}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>
                        {errors.mobileNo && <p style={{ color: 'red' }}>{errors.mobileNo}</p>}
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Email Id"
                                name="emailId"
                                value={formData.emailId}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>
                        {errors.emailId && <p style={{ color: 'red' }}>{errors.emailId}</p>}
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>

                        <Button className="w-100 btn-lg btn-primary mb-3" type="submit">
                            Register
                        </Button>

                        <div className="d-flex justify-content-between">
                            <Link to="/login" className="link">
                                Already registered? Please Login
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </>

    );
};

export default Register;
