import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import '../Css/ItemView.css';
import Slider from "./Slider";
import { Button, Container, Row, Modal, Form, Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { getFoodItems, getFoodItemsByCategory, searchFoodItems, searchItem } from "../Services/ItemsApi";
import { addItemToCart } from "../Services/Cart";
import { useNavigate } from "react-router-dom";
import { clearBrowser, isTokenValid } from "../Services/Login";
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from "../Services/StringConversion";
import LoadingOverlay from "./LoadingOverlay";
import { CartContext } from "./CartContext";
import usePreviousPathname from "../Helper/TrackLocation";
import Cookies from 'js-cookie';
import Menu from "./BottomMenu";
import { removeUnderScore, showSuccessMessage } from "../Services/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faStarHalfAlt as halfStar } from '@fortawesome/free-solid-svg-icons';

const SearchView = () => {

    const { pathname } = useLocation();

    const { query } = useParams();
    const navigate = useNavigate();

    const { cartCount, setCartCount } = useContext(CartContext);
    const { toastIdCount, setToastIdCount } = useContext(CartContext);
    const toastId = useRef(null);
    const [packages, setPackages] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (query) {
            toast.dismiss(toastIdCount)
            setProcessing(true)
            setIsLoading(true)
            searchItem(query.toLowerCase(), Cookies.get('city_id'))
                .then((response) => {
                    setProcessing(false)
                    if (response) {
                        setPackages(response);
                        // Initialize selected product for each package
                        const initialSelectedProducts = response.reduce((acc, pkg) => {
                            if (pkg.productDetails && pkg.productDetails.length > 0) {
                                acc[pkg._id] = {
                                    selectedProductName: pkg.productDetails[0].name,
                                    selectedProductPrice: pkg.productDetails[0].cityAndPrice.totalPrice.$numberDecimal,
                                    itemId: pkg._id,
                                    productId: pkg.productDetails[0]._id
                                };
                                setIsLoading(false)
                            }
                            return acc;
                        }, {});
                        setSelectedProducts(initialSelectedProducts);
                    } else {
                        console.error("Item not found");
                    }
                }).catch((error) => {
                    console.log(error);
                    setProcessing(false);
                    setIsLoading(false);
                });
        }

    }, [query, pathname]);

    const handleAddToCart = async (pkgId) => {

        if (Cookies.get('login') === 'true') {
            setProcessing(true);
            
            await isTokenValid(Cookies.get('token')).then((response) => {
                if (response === true) {

                    const productId = selectedProducts[pkgId]?.productId;
                    const itemId = selectedProducts[pkgId]?.itemId;
                    const userId = Cookies.get('userId');
                    const cityId = Cookies.get('city_id');

                    addItemToCart(userId, itemId, productId, cityId).then((response) => {
                        console.log(response);
                        setCartCount(response.cartItems.length);
                        showSuccessMessage("Item added to cart", 2000)
                        setProcessing(false);
                    }).catch((error) => {
                        console.log(error);
                        setProcessing(false);
                        setIsLoading(false);
                    });

                }
            }).catch((error) => {
                console.log(error);
                setProcessing(false);
                setIsLoading(false);
                toast.error("Session expired, please login again", { position: 'bottom-center' });
                navigate('/login')
            })

        } else {
            toast.error("Please login before adding to cart", { position: 'bottom-center' });
            navigate('/login')
        }
    };

    const handleProductChange = (pkgId, productName, totalPrice, productId) => {
        setSelectedProducts((prevSelectedProducts) => ({
            ...prevSelectedProducts,
            [pkgId]: {
                selectedProductName: productName,
                selectedProductPrice: totalPrice,
                itemId: pkgId,
                productId: productId
            },
        }));
    };



    return (

        <>
            {processing && <LoadingOverlay />}
            <div className="bg-color">
                <Container className="my-5"  >
                    <Row>
                        <Col md={2}>
                        </Col>
                        <Col md={8} className="scrollable-packages">
                            <h3 className="bestseller-title">
                                {/* {packages && packages.length > 0 ? capitalizeFirstLetter(packages[0]?.serviceCategory.name) : 'No Details Available'} */}
                                Services
                            </h3>
                            {
                                packages && packages.length > 0 ? (
                                    packages.map((pkg) => (
                                        <Card key={pkg._id} className="mb-2 package-card">
                                            <Card.Body className="package-card-body">
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="item-wb-view">
                                                            <div className="pkg-image-container">
                                                                <Card.Img src={pkg.image} className="package-images" />
                                                            </div>
                                                        </div>

                                                        <div className="item-ph-view">
                                                            <div className="pkg-image-container">
                                                                <Card.Img src={pkg.image} className="package-images" />
                                                            </div>

                                                            <div className="item-ph-view-title">
                                                                <Card.Title className="package-title">{capitalizeFirstLetter(removeUnderScore(pkg.name))}</Card.Title>
                                                                <Card.Text className="price-duration">
                                                                    <i className="bi bi-clock-history"></i> &nbsp;
                                                                    {pkg.duration?.hours > 0 ? `${pkg.duration.hours} hours ` : ''}
                                                                    {pkg.duration?.minute > 0 ? `${pkg.duration.minute} minutes` : ''}
                                                                </Card.Text>
                                                                {/* for star rating */}
                                                                <div className="star-rating d-flex align-items-center">
                                                                    {[...Array(5)].map((_, index) => {
                                                                        if (pkg.totalRating && index < Math.floor(pkg.totalRating.$numberDecimal)) {
                                                                            // Full star
                                                                            return (
                                                                                <span key={index}>
                                                                                    <FontAwesomeIcon className='item-star-icon' icon={solidStar} />
                                                                                </span>
                                                                            );
                                                                        } else if (pkg.totalRating && index === Math.floor(pkg.totalRating.$numberDecimal) && pkg.totalRating.$numberDecimal % 1 !== 0) {
                                                                            // Half star
                                                                            return (
                                                                                <span key={index}>
                                                                                    <FontAwesomeIcon className='item-star-icon' icon={halfStar} />
                                                                                </span>
                                                                            );
                                                                        } else {
                                                                            // Empty star
                                                                            return (
                                                                                <span key={index}>
                                                                                    <FontAwesomeIcon className='item-star-icon' icon={regularStar} />
                                                                                </span>
                                                                            );
                                                                        }
                                                                    })}
                                                                    <span style={{ fontSize: '15px', marginTop: '10px', color: 'grey' }}>({pkg.review && pkg.review.length})</span>
                                                                </div>

                                                                <button
                                                                    variant="primary"
                                                                    className="add-package-btn"
                                                                    style={{ marginRight: '20px' }}
                                                                    onClick={() => handleAddToCart(pkg._id)}
                                                                >
                                                                    Add to cart
                                                                </button>
                                                            </div>

                                                            <div className="item-ph-view-price">
                                                                <p className="float-end pcg-price"> ₹{selectedProducts[pkg._id]?.selectedProductPrice}</p>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col md={9} style={{ padding: '8px' }}>
                                                        <button
                                                            variant="primary"
                                                            className="float-end add-package-btn"
                                                            style={{ marginRight: '20px' }}
                                                            onClick={() => handleAddToCart(pkg._id)}
                                                        >
                                                            Add to cart
                                                        </button>
                                                        <p className="float-end pcg-price"> ₹{selectedProducts[pkg._id]?.selectedProductPrice}</p>

                                                        <div className="item-wb-view">
                                                            <Card.Title className="package-title">{capitalizeFirstLetter(removeUnderScore(pkg.name))}</Card.Title>
                                                            <Card.Text className="price-duration">
                                                                <i className="bi bi-clock-history"></i> &nbsp;
                                                                {pkg.duration?.hours > 0 ? `${pkg.duration.hours} hours ` : ''}
                                                                {pkg.duration?.minute > 0 ? `${pkg.duration.minute} minutes` : ''}
                                                            </Card.Text>
                                                            {/* for star rating */}
                                                            <div className="star-rating d-flex align-items-center">
                                                                {[...Array(5)].map((_, index) => {
                                                                    if (pkg.totalRating && index < Math.floor(pkg.totalRating.$numberDecimal)) {
                                                                        // Full star
                                                                        return (
                                                                            <span key={index}>
                                                                                <FontAwesomeIcon className='item-star-icon' icon={solidStar} />
                                                                            </span>
                                                                        );
                                                                    } else if (pkg.totalRating && index === Math.floor(pkg.totalRating.$numberDecimal) && pkg.totalRating.$numberDecimal % 1 !== 0) {
                                                                        // Half star
                                                                        return (
                                                                            <span key={index}>
                                                                                <FontAwesomeIcon className='item-star-icon' icon={halfStar} />
                                                                            </span>
                                                                        );
                                                                    } else {
                                                                        // Empty star
                                                                        return (
                                                                            <span key={index}>
                                                                                <FontAwesomeIcon className='item-star-icon' icon={regularStar} />
                                                                            </span>
                                                                        );
                                                                    }
                                                                })}
                                                                <span style={{ fontSize: '17px', marginTop: '10px', color: 'grey' }}>({pkg.review && pkg.review.length})</span>
                                                            </div>
                                                            <p className="select-product-title">Select Product</p>
                                                        </div>

                                                        <div className="item-ph-view">
                                                            <p className="select-product-title">Select Product</p>
                                                        </div>

                                                        {pkg.productDetails.map((product, index) => (
                                                            <div key={index} className="varient-tags">
                                                                <Form.Check
                                                                    type="radio"
                                                                    aria-label={`radio ${index + 1}`}
                                                                    className="item-custom-radio"
                                                                    style={{ marginLeft: '-60px', marginRight: '2px', marginRight: '10px' }}
                                                                    name={`variantRadio_${pkg._id}`} // Ensures each package's radios are grouped separately
                                                                    value={product.name}
                                                                    checked={selectedProducts[pkg._id]?.selectedProductName === product.name} // Check if this radio button is selected
                                                                    onChange={() => handleProductChange(pkg._id, product.name, product.cityAndPrice.totalPrice.$numberDecimal, product._id)} // Update selected product
                                                                />
                                                                <button style={{ textAlign: 'left', color: 'black', flexGrow: 1, fontSize: '14px', marginTop: '-1px' }} onClick={() => handleProductChange(pkg._id, product.name, product.cityAndPrice.totalPrice.$numberDecimal, product._id)} className="item_quantities_button">
                                                                    <p>{capitalizeFirstLetter(product.name)}</p>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ))
                                ) : (
                                    <div className="message-content">No service available</div> // Fallback message when there are no packages
                                )
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
export default SearchView;
