import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Col, Row, Container, Spinner, Card } from 'react-bootstrap';
import { sendMailToUser } from '../Services/Mail';
import '../Css/ContactForm.css';
import Swal from 'sweetalert2';
import { CartContext } from './CartContext';
import Loader from './Loader';
import LoadingOverlay from './LoadingOverlay';
import { getLocation } from '../Services/navigation';



// You can either load SMTPJS from a CDN or install it via npm
// import emailjs from 'smtpjs';

function App() {
    const [processing, setProcessing] = useState(false);
    const { isLoading, setIsLoading } = useContext(CartContext);
    const [cities, setCities] = useState([]); // State to store the city options
    const [errors, setErrors] = useState({ mobileNo: '' });
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        profession: '',
        homeServiceComfort: '',
        gender: '',
    });

    useEffect(()=>{
        getLocation().then((response) => {
            setCities(response.data);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    })

    const validateMobileNumber = (mobileNo) => {
        // Regex for validating 10 digit mobile number
        const mobileNumberRegex = /^\d{10}$/;
        return mobileNumberRegex.test(mobileNo);
    }

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
         // Clear error message when the user starts typing
         if (name === 'mobileNumber') {
            const mobileError = validateMobileNumber(value) ? '' : 'Invalid mobile number';
            setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: mobileError }));
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        console.log(formData);

        if(validateMobileNumber(formData.mobileNumber)){

            try {
                // Await the response from the sendMailToUser function
                await sendMailToUser(formData).then((response) => {
    
                    setIsLoading(false);
                    Swal.fire({
                        title: 'Your Response Submitted',
                        text: 'We will get back to you!',
                        icon: 'success',
                    });
    
                }).catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    alert('Error in sending message');
                });
    
            } catch (error) {
                // Catch any errors and display an alert if something goes wrong
                console.log(error);
                setIsLoading(false);
                alert('Error in sending message');
            }
        }
        else{
            alert("invalid mobile number");
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {processing && 
                <div className="d-flex justify-content-center align-items-center position-fixed w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', top: 0, left: 0, zIndex: 999 }}>
                    <Spinner animation="border" variant="light" />
                </div>
            }
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Card className="shadow-lg p-4" style={{ width: '100%', maxWidth: '500px' }}>
                <Card.Body>
                    <h3 className="text-center mb-4">Register As Professional</h3>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Form.Control
                                    placeholder="Name"
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={12}>
                                <Form.Control
                                    placeholder="Mobile Number"
                                    type="text"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </Row>
                        {errors.mobileNumber && <p style={{ color: 'red' }}>{errors.mobileNumber}</p>}

                        <Row className="mb-3">
                            <Col xs={12}>
                                <Form.Select
                                    id="profession"
                                    name="profession"
                                    value={formData.profession}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Profession</option>
                                    <option value="Beautician">Beautician</option>
                                    <option value="Technician">Technician</option>
                                    <option value="Electrician">Electrician</option>
                                    <option value="Plumber">Plumber</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={12}>
                                {isLoading ? (
                                    <Form.Control as="select" disabled>
                                        <option>Loading cities...</option>
                                    </Form.Control>
                                ) : (
                                    <Form.Select
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select City</option>
                                        {cities.map((city) => (
                                            <option key={city.id} value={city.address.city.name}>
                                                {city.address.city.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            </Col>
                        </Row>

                        {/* New Field: Are you comfortable for home services? */}
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Form.Label style={{fontSize:'14px'}}>Are you comfortable for home services?</Form.Label>
                                <div className="d-flex justify-content-start">
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        type="radio"
                                        id="homeServiceComfortYes"
                                        name="homeServiceComfort"
                                        value="Yes"
                                        checked={formData.homeServiceComfort === 'Yes'}
                                        onChange={handleChange}
                                        className="deep-black-radio"
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        type="radio"
                                        id="homeServiceComfortNo"
                                        name="homeServiceComfort"
                                        value="No"
                                        checked={formData.homeServiceComfort === 'No'}
                                        onChange={handleChange}
                                        className="deep-black-radio"
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/* New Field: Gender */}
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Form.Label style={{fontSize:'14px'}}>Gender</Form.Label>
                                <div className="d-flex justify-content-start">
                                    <Form.Check
                                        inline
                                        label="Male"
                                        type="radio"
                                        id="genderMale"
                                        name="gender"
                                        value="Male"
                                        checked={formData.gender === 'Male'}
                                        onChange={handleChange}
                                        className="deep-black-radio"
                                    />
                                    <Form.Check
                                        inline
                                        label="Female"
                                        type="radio"
                                        id="genderFemale"
                                        name="gender"
                                        value="Female"
                                        checked={formData.gender === 'Female'}
                                        onChange={handleChange}
                                        className="deep-black-radio"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={12}>
                                <Button
                                    style={{ backgroundColor: '#922d48' }}
                                    variant="primary"
                                    type="submit"
                                    className="w-100"
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
        </>
    );
}

export default App;
