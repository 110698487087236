import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import '../Css/Address.css';
import { saveAddress } from "../Services/User";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import { getLocation } from "../Services/navigation";
import { isTokenValid } from "../Services/Login";
import Menu from "./BottomMenu";
import Loader from "./Loader";
import { CartContext } from "./CartContext";
import LoadingOverlay from "./LoadingOverlay";


const AddressPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const { isLoading, setIsLoading } = useContext(CartContext);
  const [processing, setProcessing] = useState(false);
  const [addressData, setAddressData] = useState({
    address: "",
    pincode: "",
    landmark: "",
    cityName: ""
  });

  const [errors, setErrors] = useState({ pincode: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'pincode') {
      const pinCodelError = validatePincode(value) ? '' : 'Invalid pin code';
      setErrors((prevErrors) => ({ ...prevErrors, pinCode: pinCodelError }));
    }

    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    return pincodeRegex.test(pincode);
  };

  const handleSubmit = (e) => {
    console.log(addressData);
    e.preventDefault();
    console.log(location)
    if (validatePincode(addressData.pincode)) {
      setProcessing(true);
      console.log(addressData);

      saveAddress(addressData, Cookies.get('userId'))
        .then((response) => {
          if (response) {
            setProcessing(false);
            // Handle success (e.g., show a success message, redirect to another page)
            if (location.pathname === '/address') {
              navigate('/cart', { state: { showModal: true } });
            }

            if (location.pathname === '/profile') {
              navigate('/profiles', { state: { showModal: true } });
            }

            if (location.pathname === '/profiles') {
              navigate('/profile', { state: { showModal: true } });
            }
            // Redirect to a success page or another page
            // navigate('/cart', { state: { showModal: true } });
          }
        })
        .catch((error) => {
          console.log(error);
          setProcessing(false);
          setIsLoading(false);
        });
    } else {
      toast.error("Please enter a valid pincode", { position: 'bottom-center' });
    }
  };

  useEffect(() => {
    if (Cookies.get('login') === 'true') {

      
      isTokenValid(Cookies.get('token')).then((response) => {
        if (response === true) {

          window.scrollTo(0, 0);

          getLocation().then((response) => {
            setCities(response.data)
            console.log(response.data)
          }).catch((error) => {
            console.log(error);
            setProcessing(false);
            setIsLoading(false);
          });

        }
      }).catch((error) => {
        console.log(error);
        setProcessing(false);
        setIsLoading(false);
        toast.error("Session expired, please login again", { position: 'bottom-center' });
        navigate('/login')
      })
    } else {
      toast.error("Please login before adding to cart", { position: 'bottom-center' });
      navigate('/login')
    }

  }, []);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  if (!cities) {
    <div><Loader /></div>
  }

  if (isLoading) {
    <div><Loader /></div>
  }

  return (
    <>
      {processing && <LoadingOverlay />}
      <div className="address-wrap justify-content-center align-items-center d-flex">
        <dev className='address-c'>
          <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
            <div className="h4 mb-3 text-center">Add Address</div>

            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                value={addressData.address}
                onChange={handleChange}
                required
                className="p-3"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Pin Code"
                name="pincode"
                value={addressData.pincode}
                onChange={handleChange}
                required
                className="p-3"
              />
              {errors.pincode && <p style={{ color: 'red' }}>{errors.pincode}</p>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="landmark"
                value={addressData.landmark}
                onChange={handleChange}
                required
                className="p-3"
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="cityId"
                    value={addressData.city}
                    onChange={handleChange}
                    required
                    className="p-3"
                  />
                </Form.Group> */}
            <Form.Group controlId="city" className="mb-3">

              <Form.Control
                as="select"
                value={addressData.cityName = selectedCity}
                onChange={handleCityChange}
                className="p-3"
              >
                <option value="">Select a city</option>
                {cities && cities.map((city) => (
                  <option key={city.address.city_id} value={city.address.city.name}>
                    {city.address.city.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button className="w-100 btn-lg btn-primary mb-3" type="submit">
              Save Address
            </Button>
          </Form>
        </dev>
      </div>
    </>

  );
};

export default AddressPage;
