import axios from "axios";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../Css/CustomToast.css'

export const BASE_URL ='https://api.flexseva.com' 

export const PAYMENT_KEY = 'rzp_test_YkGGfXYEYeXWZo'

export const myAxios = axios.create(
    {
        baseURL: BASE_URL
    }
);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const formatOrderDateTime = (order) => {
  // Parse the slotDate to a Date object
  const date = new Date(order.slotDate);

  // Array of month abbreviations
  const monthAbbreviations = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Get the day, month index (0-11), and year
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Format the date as "DD MMM YYYY"
  const formattedDate = `${day} ${monthAbbreviations[monthIndex]} ${year}`; // e.g., "16 Oct 2024"

  const formattedSlotTime = order.slotTime;

  // Combine formatted date and time
  return `${formattedDate}, ${formattedSlotTime}`;
};

const showSuccessMessage = (message, time) => {
  const messageDiv = document.createElement('div');
  messageDiv.innerText = message;
  messageDiv.className = 'success-message'; // Add your CSS class for styling
  document.body.appendChild(messageDiv);

  // Automatically remove the message after 3 seconds
  setTimeout(() => {
      messageDiv.remove();
  }, time); // Duration in milliseconds
};

const removeUnderScore = (inputString) =>{
  const outputString = inputString.replace(/_/g, ' ');
  return outputString;
}

export {ScrollToTop, showSuccessMessage, removeUnderScore}