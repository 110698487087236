import React from "react";
import Carousel from 'react-bootstrap/Carousel';
// import First from '../Image/first.jpeg';
// import Second from '../Image/second.jpg';
// import Third from '../Image/third.jpg'
import { Image } from "react-bootstrap";
import '../Css/Slider.css'

// Import all images from a specific folder using Webpack's require.context
const importAllImages = (r) => r.keys().map(r);
const images = importAllImages(require.context('../Image', false, /\.(jpg|jpeg|png|gif)$/));  // Adjust path and image types if needed

const Slider = () => {
    
    return (
        <Carousel className="slider">
            {images.map((image, index) => (
            <Carousel.Item key={index} interval={4000}>
                <img className="d-block w-100 slide-image" src={image} alt={`Slide ${index + 1}`} />
                <Carousel.Caption>
                {/* Optional Caption */}
                </Carousel.Caption>
            </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default Slider;